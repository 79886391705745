/* eslint-disable standard/no-callback-literal */
const testSelect = function (rule, value, cb) {
  if (value) {
    cb()
  }
  cb(false)
}
const testSign = function (rule, value, cb) {
  if (value) {
    cb()
  }
  cb(false)
}

export default {
  entity_term_condition_check: [
    { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
  ],
  entity_acknowledement_check: [
    { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
  ],
  entity_crs_announcement_check: [
    { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
  ],
  entity_self_signature_key: [
    { validator: testSign, required: true, message: 'Please Sign ', trigger: 'change' }
  ]
}
